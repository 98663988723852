@import '../core/core.scss';
@import '../core/breakpoints.scss';
@import '../core/variables.scss';

.form {
  scroll-margin-top: 100px;
}

.noMargin {
  margin-bottom: 0;
}

.addButton {
  padding-left: 10px;
  font-size: 1.4rem;
  line-height: 1em;
  margin: 0;
  width: 100%;
  text-align: left;
}

.softDisableButton {
  border-color: $pink;
  font-weight: normal;

  &:hover {
    border-color: $pink;
  }
}

.footNoteError {
  color: $grey-600;
  font-size: 1.3rem;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 0;
  color: $error-red;
}

.rowWrapper {
  width: auto;
}

.rowWrapperUrl {
  > div > div > div {
    flex-direction: column;
    align-items: flex-start;
    & button > span {
      width: 100%;
    }
  }
}

.outerBox {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.summaryPar {
  font-size: $small-font-size;
  margin-top: 8px;
  margin-bottom: 12px;
}

.buttonsSummary {
  font-size: $small-font-size;
  margin-bottom: 0;
}

.innerBox {
  padding-top: 0;
  padding-bottom: 0;
}

.tableContainer {
  box-shadow: 0px 1px 3px rgba($black, 0.1);
  border: 1px solid $grey-400;
  border-radius: $border-radius-medium;
  overflow: auto;
  margin-bottom: 20px;
}
.modalBody {
  padding-bottom: 0;
}

.rowsLabelWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;

  @include medium {
    margin-top: 0;
  }
}

.rowsLabel {
  white-space: nowrap;
  font-size: 1.3rem;
  color: $grey-600;

  &:first-child {
    margin-right: 8px;
  }
  &:last-child {
    margin-left: 8px;
  }
}

.checkboxCell {
  label {
    margin: 0;
  }
}

.optional {
  color: $grey-600;
  font-weight: 400;
  display: inline;
  font-size: 1.2rem;
  line-height: 1em;
}

.heading {
  margin: 0;
  line-height: 40px;
}

.paginationRow {
  justify-content: space-between;
  padding-bottom: 16px;

  & > div {
    padding-bottom: 0;
  }
}

.panelButtonRow {
  display: flex;
}

.compactTable {
  composes: table;
  td {
    padding: 6px 8px;
  }
  thead {
    th {
      padding: 6px 8px;
      height: $compact-button-height;
      button {
        line-height: $compact-button-height;
        height: $compact-button-height;
        min-height: $compact-button-height;
      }
    }
  }
  td.checkboxCell,
  th.checkboxCell {
    width: 47px;
  }
  .createdWrapper {
    margin-bottom: 0;
    margin-top: 0;
  }
  .user {
    display: none;
  }
}

.validationCheck {
  display: inline-block;
  font-size: $x-small-font-size;
  line-height: $x-small-font-size;
  align-items: center;
  padding-top: 8px;
  position: relative;
  padding-left: 18px;

  p,
  a {
    margin: 0;
    display: inline;
    font-size: $x-small-font-size;
    line-height: $medium-font-size;

    a {
      text-decoration: underline;
    }
  }
  &:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 12px;
    width: 10px;
    height: 10px;
    background-color: $grey-400;
    border-radius: 10px;
    line-height: 1em;
    margin-right: 8px;
    line-height: $x-small-font-size;
  }
}
.validationCheckItem {
  margin-right: 6px;
  display: inline;
}
.urlValidating {
  color: $grey-600;
  &:before {
    background-color: $grey-400;
  }
}
.urlIsBad {
  // color: $error-red;
  color: $grey-600;
  &:before {
    // background-color: $error-red;
    background-color: $grey-400;
  }
}
.urlIsGood {
  color: $green;
  &:before {
    background-color: $green;
  }
}
.urlWarning {
  color: $grey-600;
  &:before {
    background-color: $grey-400;
  }
}
.intro {
  font-weight: 600;
  display: flex;
  flex-direction: column;
  button {
    text-align: left;
    // opacity: 0.6;
  }

  @include medium {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    span {
      padding: 0 16px 0 0;
    }
    button {
      background-position: 100% 50%;
      padding: 0 20px 0 0 !important;
      margin-left: 8px;
      height: 24px;
      line-height: 24px;
      display: inline-block !important;
    }
  }
}

.hoverItem {
  position: relative;
}

.inlineWrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.labelSlot {
  max-height: $button-height;
}

.tooltip {
  em {
    font-style: normal;
    font-weight: 500;
    color: $black;
  }
}

.validationCheckShortLink {
  margin: -16px 0 16px;
  .urlIsBad {
    color: $error-red;
    a {
      color: $error-red;
    }
  }
  &.urlIsBad:before {
    background-color: $error-red;
  }
}

.collapsibleToggle {
  justify-content: start !important;

  .formLabel {
    margin-bottom: 8px;
  }

  @include medium {
    .formLabel {
      margin-bottom: 0;
      width: 35%;
    }
  }
}

.radioPanel {
  border-width: 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 2em;
  flex-wrap: wrap;

  label {
    margin: 0 8px 0 0;
    padding: 0 0 12px;
    flex: 0;
  }
}

.rightInner {
  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 37px;
    background: url(../assets/pin.png);
    background-size: 23px;
    background-repeat: no-repeat;
    background-position: calc(100% - 16px) 16px;
  }
}
