@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.container {
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 20px;
}

.blank {
  background: none;
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}

.gradient {
  padding: 1px 20px 20px;
  display: block;
  height: 100%;
  width: 100%;
  background: linear-gradient(46deg, #eeeeee, #d4d4d4, #eeeeee);
  background-size: 600% 600%;
  -webkit-animation: loaderAnimation 3s ease infinite;
  -moz-animation: loaderAnimation 3s ease infinite;
  animation: loaderAnimation 3s ease infinite;
}
.fixedWidth {
  min-width: 160px;
}
.animatedDots {
  color: $grey-600;
  font-size: $small-font-size;
  line-height: 2.4rem;
}

@-webkit-keyframes loaderAnimation {
  0% {
    background-position: 0% 84%;
  }
  50% {
    background-position: 100% 17%;
  }
  100% {
    background-position: 0% 84%;
  }
}
@-moz-keyframes loaderAnimation {
  0% {
    background-position: 0% 84%;
  }
  50% {
    background-position: 100% 17%;
  }
  100% {
    background-position: 0% 84%;
  }
}
@keyframes loaderAnimation {
  0% {
    background-position: 0% 84%;
  }
  50% {
    background-position: 100% 17%;
  }
  100% {
    background-position: 0% 84%;
  }
}

.gifContainer {
  display: flex;
  justify-content: space-between;
  width: 50px;
  height: 35px;
  margin: 16px auto;

  > div {
    display: block;
    width: 16%;
    height: 100%;
    border-radius: $border-radius-small;
    animation: pulse 1.6s linear infinite;

    &:first-child {
      background-color: $tertiary-color;
    }

    &:nth-child(2) {
      background-color: $secondary-color;
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      background-color: $primary-color;
      animation-delay: 0.4s;
    }

    &:last-child {
      background-color: $quaternary-color;
      animation-delay: 0.6s;
    }
  }
}

@keyframes pulse {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }

  to {
    opacity: 1;
  }
}
