@import '../core/variables.scss';

.hide {
  display: none;
}

.qrCodeWrapper {
  position: relative;
  width: 200px;
  height: 200px;
  button {
    display: none;
  }
  &:hover {
    button {
      display: block;
    }
  }
  .copyButton {
    border-width: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0;
    padding-left: 30px !important;
    background-position: 3px center;
    box-shadow: none;
    background-color: $white;
  }
}
