@import '../core/breakpoints.scss';
@import '../core/variables.scss';

.navContainer {
  padding: 1em 2em;
  box-shadow: $box-shadow-large;
  background-color: $white;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 75px;
  z-index: 2;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    .dog {
      display: contents;
      padding: 0 !important;

      > img {
        display: block;
        max-height: 100%;
        max-width: 150px;

        @include small {
          max-width: 165px;
        }

        @include medium {
          max-width: initial;
        }
      }
    }
  }

  .hamburger {
    // removes default border on button element
    border: 0;
    height: 45px;
    width: 45px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: $primary-color;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    // positions the icon to the right and center aligns it vertically
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    margin-right: 30px;

    &:hover {
      background-color: $primary-color;
    }

    @include medium {
      display: none;
    }
  }

  .linkExpanded,
  .btnExpanded {
    margin: 30px;
    font-size: 20px;
    font-weight: bold;
    text-align: right;

    @include medium {
      margin: 0;
      font-size: 1.5rem;
      font-weight: normal;
      text-align: center;
    }
  }

  .btnExpanded {
    width: 200px;

    @include medium {
      width: 160px;
      font-size: 1.4rem;
    }
  }

  .notExpanded {
    display: none;

    @include medium {
      display: flex;
      gap: 2em;
      align-items: center;
    }
  }

  .expanded {
    display: flex;
    flex-direction: column;
    padding: 0px 10px 10px 500px;
    background-color: white;
    position: fixed;
    top: 75px;
    right: 0px;

    @include medium {
      display: flex;
      flex-direction: row;
      gap: 2em;
      align-items: center;
      // background-color: white;
      position: relative;
      top: 5px;
      right: 0px;
      gap: 2em;
      align-items: center;
      margin-right: 0px;
      padding: 0px 0px 10px 0px;
      // border: 2px solid black;
    }
  }
}
