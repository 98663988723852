@import '../core/variables.scss';
@import '../core/breakpoints';
$dot-size: 20px;

.container {
  position: relative;
  cursor: pointer;
  vertical-align: middle;
  z-index: 4;
  display: inline-block;

  img {
    width: 18px;
    height: 18px;
    margin: 0 auto;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
  }
}

.box {
  display: block;
  position: absolute;
  bottom: 0;
  width: 300px;
  max-width: 300px;
  background: $white;
  padding: 10px;
  opacity: 1;
  pointer-events: auto;
  transition-property: all, max-width;
  transition-duration: 0.5s, 1ms;
  transition-delay: 0s, 0s;
  transform: translate(-25%, 0);
  left: 50%;
  border-radius: $border-radius-small;
  border: 1px solid $grey-200;
  box-shadow: 0px 1px 3px rgba($black, 0.1);
  z-index: 2;

  @include medium {
    transform: translate(-50%, 0);
  }
  p {
    white-space: normal;
    text-align: center;
    color: $text-color;
    font-size: $small-font-size;
    margin: 0 0 8px;
    word-break: normal;
    &:last-child {
      margin: 0;
    }
    a {
      text-decoration: underline;
    }
  }
}

.showUnder {
  .box {
    transform: translate(-25%, 100%);
    @include medium {
      transform: translate(-50%, 100%);
    }
  }
}
.showOnRight {
  .box {
    width: 370px;
    max-width: 370px;
    transform: translate(0, 50%);
    margin: 0;
    padding: 16px;
    p {
      text-align: left;
    }
    @include medium {
      bottom: 50%;
      transform: translate(0, 50%);
    }
  }
}

.showOnLeft {
  .box {
    width: 370px;
    max-width: 370px;
    transform: translate(-100%, 50%);
    margin: 0;
    padding: 16px;
    p {
      text-align: left;
    }
    @include medium {
      bottom: 50%;
      transform: translate(-100%, 50%);
    }
  }
  &.showUnder {
    .box {
      transform: translate(-100%, 100%);
      @include medium {
        transform: translate(-100%, 100%);
      }
    }
  }
}

.narrow {
  .box {
    width: 240px;
    max-width: 240px;
  }
}

.dot {
  display: block;
  width: $dot-size;
  height: $dot-size;
  font-size: 14px;
  line-height: $dot-size;
  font-weight: 700;
  text-align: center;
  background-color: $grey-500;
  border-radius: $dot-size;
  color: $white;
  display: inline-block;
  margin: 0 0 0 4px;
  @include medium {
    margin: 0 0 0 12px;
  }

  &:after {
    content: '?';
  }
}

.containerHidden {
  composes: container;
  z-index: 1;

  .box {
    transition-delay: 0s, 0.5s;
    opacity: 0;
    pointer-events: none;
    bottom: 70%;
    visibility: hidden;
    max-width: 100%;
  }
}

.container:hover .children {
  text-decoration: underline;
}
