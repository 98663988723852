@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.buttonRow {
  justify-content: center;
  align-items: center;
  p {
    margin: 0 16px 0 0;
    font-size: $base-font-size;
    font-weight: 600;
  }
}
.dropZoneWrapper {
  padding-top: 4px;
  margin-bottom: 8px;
}
.dropZone {
  background-color: $grey-100;
  border-radius: $border-radius-medium;
  border: 1px solid $grey-400;
  min-height: 180px;
  align-items: center;
  display: flex;
}
.dropZoneActive {
  background-color: $grey-200;
  border: 1px solid $grey-600;
}
.dropZoneAccept {
  background-color: $grey-200;
  border: 1px solid $grey-600;
}
.dropZoneReject {
  background-color: rgba($error-red, 0.6);
  border: 1px solid $error-red;
}
.error {
  color: $error-red;
  a {
    color: $error-red;
    text-decoration: underline;
  }
}
