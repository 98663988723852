@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.logoComponent {
  height: 40px;
  width: 108px;
  margin-top: 0;
  margin-bottom: 16px;
  position: relative;
  display: flex;
  justify-content: flex-end;

  &.showUploader {
    display: block;
    height: 200px;
    width: 220px;

    p {
      margin-bottom: 8px;
    }
  }
}

.dragAndDrop {
  > div {
    margin: 0;
    padding: 0;

    > div {
      min-height: 160px;
      background-size: 80px;
      background-position: 50% 40px;
    }
  }

  p[class*='error'] {
    display: block;
    position: absolute;
    bottom: 40px;
    left: 0;
    margin: 16px;
    background-color: $grey-100;
    top: 0;
  }
}

.buttons {
  position: absolute;
  bottom: 0;
  left: 0;

  button {
    margin: 0;
  }

  &:empty {
    display: none;
  }
}

.row {
  display: flex;
  flex-direction: row;

  button {
    margin-right: 16px;
  }
}

.deleteButton {
  box-shadow: none;
}
