@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.deleteButtonContainer {
  height: 100%;

  .message {
    width: 100%;
    height: 100%;

    > button {
      height: 100%;
      margin-right: 0;
    }

    .content {
      display: flex;
      justify-content: flex-end;
      width: 0;
      opacity: 0;
      background-color: $white;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      transition: all 0.2s;

      p {
        margin: auto 16px;
        line-height: 1.8em;
        color: $error-red;

        @include medium {
          white-space: nowrap;
          line-height: 1.4em;
        }
      }

      > div {
        width: auto;
      }
    }
    > button {
      outline: none;

      @include medium {
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }

  &.deleteActionActive {
    .content {
      width: 200%;
      opacity: 1;
    }
  }
}

.confirmationButtons {
  flex-wrap: nowrap;
}
