@import '../core/variables.scss';

.wrapper {
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  > button {
    margin: 0 0 0 8px;
  }
  div > button:last-child {
    margin: 0;
  }
}
.compact {
  button {
    min-width: 65px;
    height: $compact-button-height;
    line-height: $compact-button-height;
  }
}
.simple {
  flex-direction: row;
  justify-content: space-between !important;

  button[type='button'] {
    margin: 0;
    text-align: left;
    padding-left: 0;
  }
}
.warning {
  flex-direction: row;
  justify-content: space-between;

  button[type='button'] {
    margin: 0;
    text-align: center;
  }
}
.multiButtons {
  display: flex;
  gap: 1em;

  button {
    margin: 0;

    &:last-child {
      margin: 0;
    }
  }
}

.centerAlign {
  justify-content: center;
}
