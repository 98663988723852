@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.linksTable {
  @include medium {
    max-width: 100%;
    width: 100%;
  }
  thead {
    th {
      padding-right: 34px;
      padding-top: 8px;
      padding-bottom: 8px;
      line-height: 1.8rem;
      @include medium {
        white-space: normal;
        width: 135px;
        min-width: 135px;
        // max-width: 140px;
        &.fullMatch {
          min-width: 800px;
        }

        &.linksMainHeaderCell {
          width: auto;
          padding-left: 16px !important;
          padding-right: 160px !important;
        }
      }
    }
  }
  tbody {
    th {
      font-weight: normal;
      word-break: break-all;
      @include medium {
        max-width: 1000px;
        white-space: normal;
        &.fullMatch {
          min-width: 800px;
        }
      }
    }
    td {
      width: 135px;
      max-width: 140px;
    }
  }
}
.headerCell {
  width: 140px;
  max-width: 140px;
  background: $grey-400;
  padding-right: 34px;
  padding-top: 16px;
  padding-bottom: 8px;
  line-height: 1.8rem;
  height: 58px;
  text-align: center;
  padding-right: 24px;
  padding-left: 24px;
  @include medium {
    white-space: normal;
    width: 135px;
    min-width: 135px;
  }
}
.headerTooltip {
  opacity: 0;
  font-weight: normal;
  position: absolute !important;
  right: 6px;
  top: 6px;
  // transform: translateY(-50%);
  p {
    text-align: center !important;
  }
}

.tableLinkWrapper {
  display: inline-block;
}

.tableLink {
  display: inline;
}

.linksTable {
  .highlight {
    font-weight: 600;
    background: rgba($grey-200, 0.4);
  }
  tbody tr:nth-child(odd) {
    .highlight {
      background-color: rgba($grey-200, 0.9);
    }
  }
  td.valueCell {
    text-align: center;
  }
}
.tableContainerLoading {
  tbody tr:nth-child(odd) {
    background-color: $white;
  }
}
.codeHighlighted {
  font-weight: 600;
  background: $grey-200;
  padding: 0 2px;
  margin: 0 2px;
  border-radius: 2px;
}
.tooltip {
  font-weight: normal;
  &:hover div {
    text-decoration: none !important;
  }
}
.hint {
  display: block;
  padding: 4px 8px;
  border-radius: 22px;
  font-size: $x-small-font-size;
  width: 22px;
  height: 22px;
  line-height: 12px;
  margin-top: 0;
  margin-right: 12px;
  border: 1px solid $grey-400;
  color: $grey-500;
  &.hintPartial {
    text-align: center;
    font-weight: 600;
  }
}
.notSet {
  // color: $grey-500;
  margin-right: 8px;
}
.row {
  display: flex;
}
.elementActive {
  background: $grey-400;
  box-shadow: 0 0 0 2px $grey-400;
  border-radius: 2px;
}

.highlightCheckboxContainer {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;

  > label {
    margin-bottom: 0;
  }
  .highlightCheckbox > span {
    flex-direction: row-reverse;
    align-items: center;
  }
  .highlightCheckbox > span > span {
    color: #718096;
    font-size: 1.3rem;
  }
  b {
    margin-left: 8px;
    margin-right: 0;
  }
  // @include large {
  // }
}

.orderArrow {
  // top: 0;
  // vertical-align: middle;
  // transform: translateX(-5px);
  margin: 0 0 0 3px;
  opacity: 0.8;
  display: inline-block;
  position: absolute;
  bottom: 0px;
  right: 8px;
  top: auto;
}

.linksTable {
  thead th {
    text-align: center;
    padding-right: 24px;
    padding-left: 24px;
    &:first-child {
      text-align: left;
    }
  }
  thead:hover {
    th {
      padding-left: 14px;
      padding-right: 34px;
    }
    .orderArrow {
      // opacity: 0;
    }
    .headerTooltip,
    .highlightCheckboxContainer {
      opacity: 1;
      transition: opacity 0.25s;
    }
  }
}
// .paginationRow {
//   justify-content: flex-start;
//   // padding: 16px 0;
//   // flex-direction: row-reverse;
//   .pagination {
//     justify-content: flex-start;
//   }
// }

.paginationRow {
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  flex-direction: column;
  @include medium {
    flex-direction: row;
  }
  & > div {
    padding-bottom: 0;
  }
}

.rowsLabelWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  width: auto;
  @include medium {
    margin-top: 0;
  }
}

.rowsLabel {
  white-space: nowrap;
  font-size: 1.3rem;
  color: $grey-600;

  &:first-child {
    margin-right: 8px;
  }
  &:last-child {
    margin-left: 8px;
  }
}

.headerButton {
  color: $grey-600;
  font-weight: 500;
  font-size: 1.3rem;
  width: 100%;
  text-align: left;
  padding: 0;
  outline: none;
  position: relative;
  line-height: 1.8rem;
  &:hover {
    cursor: pointer;
  }
}
.table th.headerTh {
  text-align: left;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 8px !important;
  height: 40px;
  &.successMetric {
    background-color: $light-green;
  }
  &.supportMetric {
    background-color: rgba($secondary-color, 0.3);
  }
}

.itemDragged {
  z-index: 620;
}
