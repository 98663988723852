@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.label {
  font-size: $small-font-size;
  color: $grey-800;
  font-weight: 400;
  line-height: 2.1rem;
  position: relative;

  a {
    display: inline;
    font-size: $small-font-size;
    font-weight: 400;
  }
}

.heading {
  color: $black;
  font-weight: 600;
  margin-bottom: 16px;
  display: block;
  font-size: 1.6rem;
}

.modalHeading {
  composes: heading;
  color: $grey-700;
  font-weight: 600;
  margin-bottom: 8px;
}

.pill {
  display: inline-block;
  position: relative;
  min-width: 10px;
  margin-left: 4px;
  padding: 1px 8px;
  font-size: $x-small-font-size;
  font-weight: 600;
  line-height: 20px;
  color: $white;
  background-color: $secondary-color;
  border: 1px solid $white;
  border-radius: 20px;

  > img {
    width: 14px;
    margin-left: 4px;
  }
}

.optional {
  color: $grey-600;
  font-weight: 400;
  display: inline;
  font-size: 1.2rem;
  line-height: 1em;
}
