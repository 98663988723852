.swatchContainerWrapper {
  margin: 0 0 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.swatchContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  padding: 0;
  max-width: 220px;
}

.colorField {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
  max-width: 90px;

  input {
    width: 90px;
    margin-left: 4px;
  }

  > label {
    min-width: 75px;
  }
  > span {
    margin-bottom: 0;
  }
}
