@import '../core/breakpoints.scss';
@import '../core/variables.scss';

.footerContainer {
  padding: 1em 2em;
  background-color: $grey-100;
  border-top: 1px solid $grey-300;
  margin-bottom: 0;
  width: 100%;
  height: 45px;
  position: absolute;
  bottom: 0;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      margin-bottom: 0;
    }
  }
}
