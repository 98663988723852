@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.container {
  flex: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;

  .labelText {
    display: inline-block;
    font-size: $small-font-size;
    line-height: 34px;
    max-width: 100%;
    overflow: hidden;
    color: $grey-800;
    font-weight: normal;
    white-space: nowrap;

    @include medium {
      font-size: 1.4rem;
    }
  }
}
.checkboxContainer {
  composes: container;

  b {
    background-color: $white;
  }
  &.disabled > span {
    opacity: 0.6;
  }
  .inputWrapper {
    width: auto;
    display: flex;
  }
}

.passiveContainer {
  composes: container;
  margin: 0;
  position: relative;
}

.input {
  border-radius: $border-radius-small;
  font-size: $button-font-size;
  line-height: $button-line-height;
  height: $button-height;
  display: block;
  background-color: $white;
  border: none;
  width: 100%;
  padding: 0 12px;
  font-weight: normal;
  border: 1px solid $grey-400;

  &[type='c'] {
    width: auto;
    display: inline-block;
    height: auto;
  }

  &[type='radio'] {
    width: auto;
    display: inline-block;
    display: none;
    margin-top: 10px;
    margin-right: 10px;
    height: auto;

    & ~ .radioControl {
      display: inline-block;
      width: 30px;
      height: 30px;
      background: url(../assets/radio-blank.svg) no-repeat 50% 50%;
      margin: 0 4px 0 0;
    }

    &:checked ~ .radioControl {
      background: url(../assets/radio-grey.svg) no-repeat 50% 50%;
    }
  }

  &[disabled] {
    opacity: 0.9;
    cursor: not-allowed;
  }
}

.inputWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  max-width: 100%;
}
button.clearButton {
  position: absolute;
  right: 2px;
  top: 2px;
  height: 36px;
}
.prefix {
  line-height: 40px;
  margin-bottom: 20px;
  margin-right: 8px;
  font-size: $small-font-size;
  font-style: normal;
}

.optional {
  color: $grey-600;
  font-weight: 400;
  display: inline;
  font-size: 1.2rem;
  line-height: 1em;
}

.textArea {
  width: 100%;
  border: 1px solid $grey-400;
  resize: none;
  border-radius: $border-radius-small;
  background-color: $white;
  padding: 8px 12px;

  &:not(.smallTextArea) {
    min-height: 100px;
    margin-bottom: 20px;
  }

  &.smallTextArea {
    padding-right: 35px;
  }
  &.wordBreak {
    word-break: break-all;
  }
}

.rowInput {
  border: 0;
  line-height: 4.5rem;
  border-bottom: 1px solid $grey-200;
  padding: 0;
  font-weight: 500;
  font-size: $small-font-size;
  margin: 0;
}

.input {
  &[type='checkbox'] {
    position: absolute;
    left: -9999px;
    display: inline-block;

    &:checked + b {
      background-image: url('../assets/checked-green.svg');
    }
  }
}
// checkbox styling
.container {
  b {
    display: inline-block;
    overflow: hidden;
    width: $compact-button-height;
    min-width: $compact-button-height;
    max-width: $compact-button-height;
    height: 0;
    padding: $compact-button-height 0 0 0;
    margin-right: 8px;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: 50% 8px;
    border: 1px solid $grey-400;
    border-radius: 6px;
  }
}

.list {
  font-size: 1.8rem;
  line-height: 4rem;
  font-weight: 500;
  padding-left: 25px;
  list-style-image: url('../assets/price-tick.svg');
  vertical-align: middle;

  li {
    font-size: 1.8rem;
    line-height: 4rem;
    vertical-align: middle;
  }
}

.error {
  input {
    border-color: $error-red;
  }
}

.smallLabel {
  color: $grey-600;
  font-size: $small-font-size;
  margin-bottom: 8px;
  margin-left: 4px;
}

.clickEditInput {
  position: relative;
  width: 100%;

  &.clickEditInputTextArea {
    &.clickEditInputActive {
      margin-bottom: 16px;
      button:last-child {
        margin-right: 0;
      }
    }
  }
  label {
    opacity: 1;
    display: block;
    z-index: 1;
    width: 100%;
    line-height: 42px;
    height: 42px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 40px;
    color: $black;
    font-weight: normal;
    // display: none;
  }

  input {
    width: 100%;
    padding-right: 30px;
    // position: absolute;
    // top: 0;
  }

  .clickEditInputContainer {
    // display: block;
  }

  @include medium {
    .clickEditInputContainer {
      // display: none;
    }

    label {
      display: block;

      &:hover {
        background: url('../assets/edit.svg') no-repeat 100% 50%;
        background-size: 30px;
      }
    }

    input {
      // opacity: 0;
    }
  }

  .markDown {
    &:hover {
      cursor: pointer;
      &:after {
        position: absolute;
        right: 4px;
        top: 4px;
        display: block;
        background-color: $grey-300;
        padding: 8px 16px;
        border: 1px solid $grey-400;
        content: 'Edit';
        border-radius: $border-radius-small;
      }
    }
  }
}
label.clickEditInputEmpty {
  border: 1px solid $grey-300;
  border-radius: $border-radius-small;
  height: 40px;
}
.showBorder {
  label {
    border: 1px solid $grey-300;
    border-radius: $border-radius-small;
    height: 40px;
    padding-left: 14px;
  }
}
.clickEditViewOnlyInput {
  composes: clickEditInput;
  border-width: 0 !important;
  padding: 0 16px 1px 0 !important;
  &.viewOnlyShowIcon {
    padding: 0 !important;
  }

  &:focus {
    outline: none;
  }

  &.viewOnlyShowIcon {
    label {
      &:hover {
        background: url('../assets/edit-grey.svg') no-repeat 100% 50%;
        background-size: 30px;
      }
    }
  }

  @include medium {
    label {
      &:focus {
        outline: none;
      }
      &:hover {
        background-image: none;
      }
    }
  }
  .markDown {
    border-width: 0;
    padding: 0 16px 1px 0;
    &:hover {
      cursor: auto;

      &:after {
        display: none;
      }
    }
    &:focus {
      outline: none;
    }
  }
}

button.inputButton {
  position: absolute;
  line-height: 34px;
  height: 34px;
  top: 3px;
  right: 8px;
  margin: 0;
  z-index: 2;
}

button.cancelButton {
  position: absolute;
  line-height: 34px;
  height: 34px;
  top: 3px;
  right: -76px;
  margin: 0;
  z-index: 2;
}
.markDown {
  margin-bottom: 16px;
  position: relative;
  border-radius: $border-radius-small;
  border: 1px solid $grey-400;
  padding: 8px 16px 0;
  * {
    color: $grey-700;
  }
  p {
    margin: 0 0 8px;
    font-size: $small-font-size;
  }
  li {
    font-size: $small-font-size;
    margin: 0 0 4px;
  }
}
.buttonRow {
  margin-top: 8px;
}
.clickEditInputContainer {
  margin: 0;
}

.clickEditInputActive {
  label {
    display: none;
  }
  padding-top: 1px;
  padding-bottom: 2px;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
.required {
  color: $error-red;
  font-weight: 600;
  display: inline-block;
  padding: 0 0 0 3px;
}
.hide {
  label {
    color: $grey-500;
    text-decoration: line-through;
  }
}

.shortLinkInput {
  min-width: 200px;
  margin-left: 12px;
  button {
    &:hover {
      border-width: 0;
    }
  }
  .prefix {
    display: flex;
    justify-content: flex-start;
    background-color: transparent;
    border-width: 0;
    margin: 0;
    padding: 0;
    height: $button-height - 2px;
    &:hover {
      background-color: transparent;
    }
  }
  input {
    border: none;
    outline: none;
    height: $button-height - 2px;
    padding: 0 30px 0 0;
    color: $grey-600;
  }

  & > span {
    position: relative;
    display: flex;
    justify-content: flex-start;
    border-radius: $border-radius-small;
    line-height: $button-line-height;
    height: $button-height;
    background-color: $white;
    border: none;
    width: 100%;
    padding: 0 12px;
    font-weight: normal;
    border: 1px solid $grey-400;

    & > span:first-child {
      margin: 0;
    }
  }
  p {
    margin: 0;
  }
  .shortLinkInputPrefixDiv {
    margin: 0 12px;
    color: $grey-600;
    line-height: 38px;
  }
  .shortLinkInputPrefix {
    color: $grey-600;
    line-height: 38px;
  }
  .shortLinkInputButton {
    position: absolute;
    top: 0;
    right: 0;
    height: 38px;
    background-color: transparent;
  }
  &.shortLinkInline {
    & > span {
      border-width: 0;
      padding: 0;
      background-color: transparent;

      input {
        color: $text-color;
        background-color: transparent;
      }
      .shortLinkInputPrefix {
        color: $text-color;
      }
      .shortLinkInputPrefixDiv {
        color: $text-color;
        margin: 0;
        display: inline;
      }
    }
  }
}

.shortLinkInputAnimated {
  .shortLinkInputButton {
    animation: rotating 0.5s linear infinite;
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
