@import '../core/variables.scss';
@import '../core/core.scss';

.topBox {
  padding-bottom: 0;
}

.sideColumn {
  & > div {
    border-width: 0 !important;
    box-shadow: none !important;
  }
}
.mainColumnInner {
  border: 1px solid $grey-300;
  width: 220px;
  max-width: 100%;
  height: 220px;
  padding: 10px;
  margin-bottom: 16px;

  canvas {
    width: 200px !important;
    max-width: 100%;
    height: 200px !important;
  }
}

.hide {
  display: none;
}

.row {
  margin-bottom: 16px;
  display: flex;
  width: 100%;
  flex-direction: column;
  &:last-child {
    margin-bottom: 0;
  }
}

.alignRow {
  display: flex;
  width: 100%;
  flex-direction: row;
  label {
    max-width: 90px;
    margin: 0;
  }
}
.checkboxRow {
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  label {
    flex-basis: 0;
    margin: 0 0 16px;
  }
}
.inlineTitle {
  em {
    color: $grey-700;
    font-weight: 600;
    margin-bottom: 0;
    font-size: 1.6rem;
  }
  b {
    margin-top: 5px;
  }
}

.footerContainer {
  display: flex;
  flex-direction: column;

  @include medium {
    flex-direction: row;
  }
}

.downloadButton {
  width: 100%;
  margin-bottom: 0;

  @include medium {
    width: inherit;
  }
}

.saveNote {
  composes: note;
  margin: 0 auto 15px;
  padding-bottom: 8px !important;
  position: relative;
  background-color: $light-yellow;

  strong {
    display: inline;
  }

  &:before {
    position: absolute;
    bottom: 0;
    right: 50%;
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    border-bottom: 10px solid transparent;
    border-left: 10px solid $light-yellow;
    border-top: 10px solid transparent;
    border-right: 10px solid transparent;
    z-index: 1;
    transform: translate(10px, 100%) rotate(90deg);
  }

  @include medium {
    margin: 0 15px 0 auto;

    &:before {
      transform: translate(20px, 50%);
      right: 0;
      bottom: 50%;
    }
  }
}

.passiveInput {
  white-space: normal;
  padding: 0;
  border-width: 0;
  max-width: 100%;
  display: block;
  word-break: break-all;
}
