@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.wrapper {
  display: flex;
  flex-direction: column;

  @include large {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.columnWrapper {
  box-sizing: border-box;
  transition: 0.3s padding;
}

.box {
  box-sizing: border-box;
  border: 1px solid $grey-400;
  border-radius: $border-radius-small;
  padding: 0;
  margin: 0 0 24px 0;
  background-color: $white;
  position: relative;
}

.innerBox {
  position: relative;
  padding: 16px 10px;

  @include medium {
    padding: 20px;
  }

  > button:last-child {
    margin-bottom: 0;
  }
}

.noPadding {
  padding: 0;

  @include medium {
    padding: 0;
  }
}

.divider {
  border-bottom: 1px solid $grey-300;
}

.mainColumn {
  align-self: clear;

  &.transparent {
    background-color: transparent;
  }
  &.equalColumn {
    padding: 12px;
  }
  @include large {
    width: 65%;

    &.equalColumn {
      width: 50%;
    }
  }
}

.sideColumn {
  @include large {
    width: 35%;
    min-width: 300px;
    margin-left: 32px;

    &.equalColumn {
      width: 50%;
      margin-left: 0;
      padding: 12px;
    }
  }
}

.fixedColumn {
  @include large {
    position: sticky;
    top: $offset-top;
    z-index: 1;
  }
}

.box {
  &.panel {
    border-radius: 0;
    border: 0;
    height: 100%;
    margin-bottom: 0;

    @include medium {
      border-left: 1px solid $grey-300;
      box-shadow: -1px 0px 0 $grey-200;
    }
  }
}
