@import '../core/core.scss';
@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.noPadding {
  padding-top: 0;
  padding-bottom: 0;
}

.noPaddingBottom {
  padding-bottom: 0;
}

.inlineRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 16px;

  button {
    margin: 0 0 0 16px;
    box-shadow: none;
  }
}
#generated-code {
  padding-right: 10px !important;
}

.labelSlot {
  max-height: $button-height;
}

.tooltip {
  em {
    font-style: normal;
    font-weight: 500;
    color: $black;
  }
}

.qrCodeWrapper {
  margin-bottom: 16px;
}

.bold {
  font-weight: 600;
  font-size: 1.6rem;
}

.customiseButton,
.downloadButton {
  right: 0;
  position: absolute;
  margin-bottom: 0 !important;
  background-color: $white;
  border-width: 0;
  box-shadow: none;
  border-radius: 0;
  &:hover {
    border-width: 0;
    box-shadow: none;
    background-color: $white;
    text-decoration: underline;
  }
}

.customiseButton {
  top: 0;
}
.downloadButton {
  bottom: 0;
}

.saveNote {
  composes: note;
  margin: 0;
  padding-bottom: 8px !important;
  position: relative;
  background-color: $light-yellow;
  width: 200px;

  strong {
    display: inline;
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid $light-yellow;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    transform: translateY(-20px);
    z-index: 1;
  }
}
