@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.benefitTooltip span {
  margin-left: 6px;
}

.buttonRow {
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;

  > a {
    width: 100%;
    min-width: 48%;
    flex-grow: 1;
    margin: auto;
    margin-right: auto !important;

    @include medium {
      width: auto;
    }
  }
}

.uplifterListItem::before {
  background-image: url(../assets/uplifter-arrow-blue.png);
  transform: none;
}
