@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.blogFootnote {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 0px;
  font-weight: 600;
}

.blogArticleDescription {
  color: black;
}

// articles

.articleTitle {
  margin-bottom: 20px;
}

.articleItem {
  margin-bottom: 80px;
  background-color: white;
}

.articleContainer {
  padding: 20px;
  width: 100%;
  margin: auto;
  margin-bottom: 20px;

  @include medium {
    margin-bottom: 80px;
  }
}

.quote {
  margin-bottom: 20px;
  text-align: left;
}

.searchInput {
  width: 50%;
  height: 35px;
  margin-bottom: 20px;
  border: 0.5px solid $grey-400;
  border-radius: 4px;
  background-image: url('../assets/search.svg');
  background-repeat: no-repeat;
  background-position: 15px;
}

::placeholder {
  font-size: 15px;
}

.searchIinput:focus-visible {
  border: 2px solid red;
}

.searchResultDivHide {
  display: none;
}

.searchResultDivShow {
  display: block;
  background-color: white;
  padding: 10px 20px 1px;
  padding-top: 10px;
  border: 0.5px solid $grey-400;
  border-radius: 4px;
}

.miniSearchInput {
  width: 100%;
  height: 35px;
  border: 0.5px solid $grey-400;
  border-radius: 4px;
  background-image: url('../assets/search.svg');
  background-repeat: no-repeat;
  background-position: 10px;
  margin-left: 0px;
  padding: 20px;
  padding-left: 40px;
  padding-top: 21px;
}

.searchResultLink {
  font-size: 15px;
}

.utmWikiSearch {
  margin-bottom: 20px;
}

.innerBox {
  padding: 0px 0px 30px 0px;
}

.outerBox {
  @include medium {
    top: 116px;
  }
}

.innerBoxTwo {
  padding: 0px 0px 30px 0px;
}

.lastUpdated {
  color: $grey-600;
  font-size: 14px;
  font-style: italic;
}

.articleCardInner {
  padding-bottom: 5px;
}
