// --------------- variables --------------------
$primary-color: #007d9b;
$secondary-color: #0fc3f0;
$tertiary-color: #e61969;
$quaternary-color: #ffd70f;

$white: #fff;
$black: #1a202c;
$pink: #ffeef5;

$error-red: #dc3545;
$green: #38a169;
$light-green: #c6f6d5;
$light-yellow: #fdf3ce;

$text-color: #333;

// Greys
$navigation-grey: #f9f9f9;
$grey-100: #f7fafc;
$grey-200: #edf2f7;
$grey-300: #e2e8f0;
$grey-400: #cbd5e0;
$grey-500: #a0aec0;
$grey-600: #718096;
$grey-700: #4a5568;
$grey-800: #2d3748;
$grey-900: #1a202c;

$xx-small-font-size: 1.1rem;
$x-small-font-size: 1.3rem;
$small-font-size: 1.4rem;
$base-font-size: 1.5rem;
$medium-font-size: 1.8rem;
$large-font-size: 2rem;
$base-line-height: 2.2rem;

$h1-font-size: 2.2rem;
$h1-line-height: 4rem;

$h1-font-size-mobile: 2rem;
$h1-line-height-mobile: 2.8rem;

$h2-font-size: 1.8rem;
$h2-line-height: 2.6rem;

$h2-font-size-mobile: 1.6rem;
$h2-line-height-mobile: 2.4rem;

$h3-font-size: 1.6rem;
$h3-line-height: 2.4rem;

$h4-font-size: 1.4rem;
$h4-line-height: 2rem;

$compact-button-height: 26px;
$button-height: 40px;
$button-font-size: 1.4rem;
$button-line-height: 4rem;

$border-radius-small: 4px;
$border-radius-medium: 6px;
$border-radius: 50px;
$animation-duration: 0.6s;

$box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
$box-shadow-large: 0px 1px 4px rgba(118, 130, 150, 0.33);

/* Breakpoints */
$small-screen-min-width: 415px; // iPhone 8
$medium-screen-min-width: 769px;
$medium-wide-screen-min-width: 850px; // arbitrary horizontal mobile width
$large-screen-min-width: 1024px;
$site-container-width: 1200px;

$offset-top: calc(2em + 75px);
