@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.container {
  display: block;
  background-color: $primary-color;
  border-radius: $border-radius-small;
  width: auto;
  color: $white;
  padding: 0 12px;
  border: none;
  margin: 0 0 0px;
  cursor: pointer;
  font-size: $x-small-font-size;
  line-height: 38px;
  text-align: center;
  border: 1px solid $primary-color;
  height: $button-height;
  white-space: nowrap;
  position: relative;
  transition: 0.5s all;

  &:hover {
    border: 1px solid $primary-color;
    color: $primary-color;
    background-color: $white;
    text-decoration: none;
  }

  &.disabled,
  &:disabled {
    background-color: $grey-400;
    border-color: $grey-400;
    color: $grey-700;

    &:hover {
      background-color: $grey-100;
      border-color: $grey-100;
      color: $grey-600;
      cursor: default;
    }
  }

  @include small {
    font-size: $button-font-size;
  }
}

.alignLeft {
  margin: 0 0 16px;
}

.alignRight {
  margin: 0 0 16px;
}

.whiteWithBorder {
  composes: container;
  background-color: $white;
  border-color: $grey-300;
  color: $grey-700;
  font-weight: 600;
  margin: 0 0 16px;
  box-shadow: 0px 1px 3px 0px $grey-400;

  &:hover {
    background-color: $white;
    border-color: $black;
    color: $black;
  }
}

.edit {
  border-color: $grey-500;
  background-image: url(../assets/icon-cog.svg);
  background-repeat: no-repeat;
  background-position: 8px 50%;
  padding-left: 36px !important;
  &:hover {
    text-decoration: underline;
  }
}

.copy {
  composes: whiteWithBorder;
  background-image: url(../assets/icon-copy.svg);
  border-width: 0;
  border-radius: 0;
  background-size: 22px;
  background-repeat: no-repeat;
  background-position: left 50%;
  padding-left: 25px !important;
  transition: none;
  &:hover {
    border-width: 0;
  }
}
.qrCode {
  composes: whiteWithBorder;
  background-color: $white;
  background-image: url(../assets/icon-qr.svg);
  border-width: 0;
  background-size: 22px;
  background-repeat: no-repeat;
  background-position: 7px 50%;
  padding-left: 25px !important;
  transition: none;
  &:hover {
    border-width: 0;
    background-color: $white;
  }
}

.delete {
  composes: copy;
  background-image: url(../assets/bin.svg);
  background-size: 18px;
  background-position: 50% 50%;
}

.clear {
  composes: container;
  background-color: $white;
  border: 0;
  color: $black;
  padding: 0 0 0 30px !important;
  // line-height: 2rem;
  font-size: 1.4rem;
  font-weight: 600;
  margin: 8px 0 16px;
  background-image: url(../assets/icon-close-black.svg);
  opacity: 0.6;
  background-repeat: no-repeat;
  background-position: 8px 50%;
  background-size: 13px;
  margin: 0;

  &:hover {
    background-color: $white;
    border: 0;
    color: $black;
    text-decoration: underline;
  }
}

.hollowBorder {
  composes: container;
  background-color: #fff;
  border: 1px solid $primary-color;
  color: $primary-color;
  transition: 0.5s all;

  &:hover {
    background-color: $primary-color;
    border: 1px solid $primary-color;
    color: #fff;
  }
}

.link {
  composes: container;
  background-color: transparent;
  border-width: 0;
  color: $primary-color;
  transition: none;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  display: inline;
  padding: 0;
  margin: 0;

  &:hover {
    background-color: transparent;
    border-width: 0;
    color: $tertiary-color;
    text-decoration: underline;
  }
}

.containerPink {
  composes: container;
  background-color: $tertiary-color;
  border: 1px solid $tertiary-color;
  color: #fff;
  transition: 0.5s all;

  &:hover {
    background-color: #fff;
    border: 1px solid $tertiary-color;
    color: $tertiary-color;
  }
}

.selectBox {
  composes: container;
  height: auto;
  line-height: 4rem;
  border-radius: 0px;
  vertical-align: middle;
  background-color: transparent;
  border: 0;
  border-bottom: 0;
  color: $black;
  font-size: $small-font-size;
  font-weight: 600;
  line-height: $button-line-height;
  text-transform: none;
  margin: 0;
  padding: 0 10px;
  width: 100%;
  max-width: none;
  text-align: left;
  border-radius: 0;
  outline: none;

  &:last-child {
    margin-bottom: 0;
    border: 0;
    &:hover {
      border: 0;
    }
  }

  span {
    position: relative;
    // top: -2px;
  }

  &:hover {
    background-color: $grey-400;
    color: $black;
    border: 0;
  }

  &.disabled,
  &:disabled {
    background-color: transparent;
    border-color: transparent;
    color: $grey-400;

    &:hover {
      background-color: transparent;
      border-color: transparent;
      color: $grey-400;
      cursor: default;
    }
  }
}

.flatPink {
  composes: containerPink;
  background-color: $pink;
  color: $tertiary-color;
  font-weight: 400;
  border: 1px solid $tertiary-color;
  outline-color: $tertiary-color;

  &:hover {
    background-color: $pink;
    border: 1px solid $tertiary-color;
  }
}

.share {
  composes: container;
  padding-right: 30px;
  background-image: url(../assets/icon-share-white.svg);
  background-repeat: no-repeat;
  background-position: 90% 50%;
  margin: 0;
  &:hover {
    background-image: url(../assets/icon-share.svg);
    &.disabled,
    &:disabled {
      background-image: url(../assets/icon-share-grey.svg);
    }
  }
}

.pinkBorder {
  composes: flatPink;
  background-color: $white;
}

.flatBlue {
  composes: container;
  background-color: $white;
  color: $primary-color;
  font-weight: 600;
  border: 1px solid $primary-color;
  outline-color: $primary-color;

  &:hover {
    color: $white;
    background-color: $primary-color;
    border: 1px solid $primary-color;
  }
}

.pill {
  border-radius: $border-radius;
  padding: 0 20px;
}

.buttonRow {
  display: flex;
  min-height: 40px;
  justify-content: center;
  max-width: 100%;
  flex-wrap: wrap;
  width: 100%;

  @include medium {
    justify-content: flex-end;
  }

  & > span,
  & > div,
  & > a {
    margin-bottom: 0;
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }

  & > button {
    margin-right: 16px;
    width: auto;
    min-width: 75px;
    padding: 0 16px;
    margin-bottom: 0;
    text-align: center;
    line-height: 100%;
    height: 40px;
  }

  > .image {
    height: auto;
    min-width: auto;
  }
  @include medium {
  }
  &.centerAlign {
    align-items: center;

    .container {
      margin: 0;
    }
  }
}

.tooltip {
  display: none;
  position: absolute;
  white-space: normal;
  line-height: 1.6em;
  font-weight: 500;
  font-size: $small-font-size;
  text-align: left;
  color: $grey-700;
  z-index: 10;
  background: $white;
  width: 300px;
  padding: 12px 16px;
  border-radius: $border-radius-small;
  border: 1px solid $grey-400;
  bottom: 0;
  left: 0;
  transform: translate(0, 100%);
  margin-top: -3px;

  @include medium {
    left: 50%;
    transform: translate(-50%, 100%);
  }
}

.hasTooltip {
  &:hover {
    text-decoration: underline;

    .tooltip {
      display: block;
    }
  }
}

.noBorder {
  border-width: 0;
  height: $button-height + 2px;
  &:hover {
    border-width: 0;
  }
}

.image {
  cursor: pointer;
  border: 0;
  background: none;
  padding: 4px !important;
  border-radius: $border-radius;

  &:hover {
    box-shadow: $box-shadow;
  }
}

.text {
  composes: container;
  display: inline-block;
  background-color: transparent;
  border: 0;
  color: $primary-color;
  padding: 0 16px 0 0;
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0;
  line-height: inherit;
  height: auto;

  &:hover {
    background-color: transparent;
    border: 0;
    color: $primary-color;
    text-decoration: underline;
  }

  &:disabled {
    background-color: transparent;
    text-decoration: line-through;
  }

  &::after {
    content: '';
    background-image: url(../assets/drop-down-arrow.svg);
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
  }
}

.swatch {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  border: 0;
  margin: 4px 2px;
  padding: 0;
  cursor: pointer;
  box-shadow: $box-shadow;
}
