@import './variables.scss';
@import './breakpoints.scss';
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

// ------------------- globals ------------------

// styling for
span {
  &.pink {
    color: $tertiary-color;
  }

  &.auditColor {
    color: $secondary-color;
  }

  &.explainColor {
    color: $tertiary-color;
  }

  &.trackColor {
    color: $primary-color;
  }

  &.box {
    background-color: #edf2f7;
    border-radius: 4px;
    color: #333;
    display: inline-block;
    font-weight: 500;
    padding: 0 8px;
  }
}

html,
body {
  display: block;
  width: 100vw;
  max-width: 100%;
  box-sizing: border-box;
  font-size: 11px;
  background-color: $grey-100;
  margin: 0 auto;
  color: $text-color;
  font-weight: 400;
  line-height: 1;
  height: 100%;

  * {
    box-sizing: border-box;
    font-family: 'Nunito', sans-serif;
  }
}
#root {
  position: relative;
  height: auto;
  min-height: 100vh;
}

main {
  padding: $offset-top 2em 45px 2em;
}

a,
p a {
  font-size: $small-font-size;
  line-height: 2.4rem;
  font-weight: 400;

  @include medium {
    font-size: $base-font-size;
    line-height: $base-line-height;
  }
  color: $primary-color;
  text-decoration: none;
  cursor: pointer;

  &:focus {
    outline: 1px dotted #212121;
    outline: 5px auto -webkit-focus-ring-color;
  }
}

a:hover {
  text-decoration: underline;
}

p,
td,
tr,
li,
table {
  font-size: $small-font-size;
  line-height: 2.4rem;

  @include medium {
    font-size: $base-font-size;
  }
}
table {
  line-height: 1.8rem;
}

ol {
  counter-reset: numList;

  > li {
    display: list-item;
    position: relative;
    padding-left: 8px;
    overflow-wrap: anywhere;
    list-style: none;

    &::before {
      counter-increment: numList;
      content: counter(numList);
      position: absolute;
      left: -25px;
      font-size: 1.2rem;
      font-weight: 600;
      text-align: center;
      color: $white;
      line-height: 22px;
      width: 22px;
      height: 22px;
      background: $primary-color;
      border-radius: 22px;
    }
  }
}

ul {
  margin: 16px auto;
  padding-left: 0;

  li {
    font-size: $small-font-size;
    padding-left: 27px;
    list-style: none;
    position: relative;

    &:before {
      content: '';
      height: 24px;
      width: 24px;
      position: absolute;
      left: 0;
      background-image: url('../assets/pawprintdarkblue.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 18px;
      transform: rotate(25deg);
    }
  }

  &.negative > li:before {
    background-image: url('../assets/pawprintdarkpink.png');
    transform: rotate(-25deg);
  }
}

tr {
  line-height: 2.4rem;
  min-height: 4rem;
  vertical-align: middle;
  align-items: center;
  color: $text-color;
}

tr th {
  background-color: $grey-100;
}

tr:nth-child(even) {
  background: $grey-100;
}
tr:nth-child(odd) {
  background: $white;
}

th {
  text-align: left;
}

p {
  margin: 0 0 16px;
}

h1 {
  color: $black;
  font-size: $h1-font-size-mobile;
  line-height: $h1-line-height-mobile;
  margin: 0 auto 10px;

  @include medium {
    font-size: $h1-font-size;
    line-height: $h1-line-height;
  }
}

h2 {
  font-size: $h2-font-size-mobile;
  line-height: $h2-line-height;
  margin: 0 auto 8px;

  @include medium {
    font-size: $h2-font-size;
    line-height: $h2-line-height;
  }
}

h3 {
  font-size: $h3-font-size;
  line-height: $h3-line-height;
  font-weight: 600;
  margin: 0 auto 16px;
}

h4 {
  font-size: $h4-font-size;
  line-height: $h4-line-height;
  font-weight: 600;
  margin: 0 auto 10px;
}

button {
  font-size: $button-font-size;
  line-height: $button-line-height;
}

select {
  box-sizing: border-box;
  display: block;
  flex: 100%;
  width: 100%;
  margin: 0 auto 20px;
  border-radius: $border-radius;
  font-size: $button-font-size;
  line-height: $button-line-height;
  background-color: #e2e2e2;
  border: none;
  padding: 0 20px;
}

label[data-type='select-box'] {
  box-sizing: border-box;
  display: block;
  flex: 100%;
  width: 100%;
  margin: 0 auto 20px;
  border-radius: $border-radius;
  font-size: $button-font-size;
  line-height: $button-line-height;
  height: $button-line-height;
  background-color: #e2e2e2;
  border: none;
  padding: 0 20px;
  overflow: hidden;

  select {
    padding: 0;
    margin: 0;
  }
}

pre {
  background-color: $grey-200;
  border-radius: $border-radius-small;
  line-height: 2.4rem;
  padding: 8px 8px;
}
code {
  // box-shadow: 0 0 0 1px rgba(9,30,66,.13);
  color: $grey-900;

  margin: -1px 2px;
  white-space: break-spaces;
}

textarea {
  resize: none;
}

.boxed {
  display: inline-block;
  background-color: $grey-200;
  padding: 0 8px;
  color: $text-color;
  font-weight: 500;
  border-radius: $border-radius-small;
}
p .boxed {
  margin-left: 4px;
}

.tableContainer {
  box-shadow: 0px 1px 3px rgba($black, 0.1);
  border: 1px solid $grey-400;
  border-radius: $border-radius-medium;
  overflow: auto;
  margin-bottom: 20px;
}

.table {
  border: 0;
  width: 100%;
  white-space: nowrap;
  border-spacing: 0;
  padding: 0;
  border-collapse: collapse;
  // transition: 0 margin;

  tbody tr {
    background-color: $white;
  }

  tbody tr:nth-child(odd) {
    background-color: $grey-100;
  }

  thead {
    th {
      position: relative;
      text-align: left;
      padding: 0 24px 0 16px;
      border-right: 1px solid $grey-400;
      color: $grey-600;
      font-weight: 500;
      background-color: $white;
      white-space: nowrap;
      height: 60px;
      border-bottom: 1px solid $grey-400;

      &:first-child {
        border-radius: $border-radius-medium 0 0 0;
      }

      &:last-child {
        border-radius: 0 $border-radius-medium 0 0;
        border-right: 0;
      }

      button {
        position: relative;
      }
    }
  }

  th,
  td {
    min-height: 60px;
    font-size: $x-small-font-size;
    line-height: 1.6em;
    text-align: left;
    padding: 6px 10px;
    border-right: 1px solid $grey-400;
    @include medium {
      min-width: 120px;
      padding: 8px 16px;
      white-space: nowrap;

      p {
        white-space: normal;
      }
    }

    &.checkboxCell {
      width: 50px !important;
      min-width: 50px !important;
      max-width: 50px !important;
      padding-left: 10px;
      padding-right: 10px;
      // border-right-width: 0;
      > span {
        margin: 0;
      }
      b {
        margin: 0;
      }
      label {
        margin: 0;
      }
    }
    label {
      font-weight: normal;
    }
    &:last-child {
      border-right-width: 0;
    }
  }

  td,
  th {
    &.actionCell {
      vertical-align: top;
      text-align: right;
      padding: 0;
      margin: 0;
      border-right: 0;
    }
    &.statusCell {
      width: 100px;
      min-width: 100px;
      // padding-left: 8px;
      // padding-right: 8px;
    }
  }

  // th {
  // position: sticky;
  // top: 60px;
  // z-index: 2;

  // @include site-width {
  //   top: 0;
  // }
  // }
  tbody tr {
    transform: scale(1);
  }
}

.table {
  tbody tr:nth-child(odd) {
    background-color: $grey-100;
    .tdCode {
      button {
        background-color: $grey-100;
      }
    }
  }
}

.itemLink {
  display: flex;
  align-items: center;

  p {
    font-weight: 600;
    padding-left: 12px;
    font-size: 1.4rem;
    color: $grey-900;
    margin: 0;
  }

  button {
    display: inline-flex;
    padding: 0;
    margin: 0;
  }
}

.note {
  // font-style: italic;
  display: block;
  background-color: $grey-200;
  padding: 8px 16px;
  color: $text-color;
  font-weight: 400;
  border-radius: $border-radius-small;

  strong {
    font-weight: 600;
    color: $black;
    display: block;
    margin-bottom: 4px;
  }
}
p.note {
  padding-bottom: 16px;
}

.inlineDot {
  &:before {
    content: ' ';
    display: inline-block;
    width: 5px;
    height: 5px;
    background-color: $grey-500;
    border-radius: 5px;
    margin: 0 6px;
    line-height: 1em;
    vertical-align: middle;
  }
}

.summary {
  white-space: nowrap;
  margin: -8px 0 8px 0;
  color: $black;
  align-self: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  display: block;

  @include medium {
    display: inline;
    width: auto;
    // margin-top: 0;
    margin-bottom: 0;
  }
}

.summaryRow {
  line-height: 1.2em;
  padding: 4px 0 8px;
  vertical-align: bottom;
  @include medium {
    padding: 0;
  }
}

.animatedDots {
  position: relative;
  display: inline-block;
  width: auto;
  white-space: nowrap;

  span > b {
    animation: grow 1s linear infinite;
    display: inline-block;
    overflow: hidden;
    vertical-align: bottom;
    b {
      font-weight: normal;
      width: 6px;
      display: inline-block;
      &:after {
        content: '.';
        padding: 0 1px;
        width: 3px;
        line-height: 18px;
      }
    }
  }
}

.divider {
  border-top: 1px solid #cbd5e0;
  margin: 16px 0;
}

@keyframes grow {
  0% {
    width: 0;
  }

  100% {
    width: 25px;
  }
}

#notificationPolicyLink {
  font-size: 12px;
}

#onetrust-accept-btn-handler,
#accept-recommeded-btn-handler {
  background-color: $primary-color !important;
}

#onetrust-pc-btn-handler {
  color: $primary-color !important;
  border-color: $primary-color !important;
}

.ot-floating-button__open {
  background-color: $primary-color !important;
  border-radius: 50%;
  background-image: url('../assets/cookie.svg');
  background-position: center;
  background-size: 70%;
  background-repeat: no-repeat;
}

#onetrust-pc-sdk #accept-recommended-btn-handler,
#onetrust-pc-sdk .save-preference-btn-handler {
  background-color: $primary-color !important;
  border: $primary-color;
}

#onetrust-consent-sdk #onetrust-pc-sdk .privacy-notice-link,
#onetrust-pc-sdk .ot-cat-item > button {
  color: $primary-color !important;
}

#onetrust-reject-all-handler,
button.ot-pc-refuse-all-handler {
  background-color: white !important;
  border-color: $primary-color !important;
  color: $primary-color !important;
}

#onetrust-sdk-container *:focus,
#onetrust-sdk-container *,
#onetrust-banner-sdk:focus {
  outline: none !important;
  border: none !important;
}

table,
th {
  border: 0.8px solid $grey-500;
  border-collapse: collapse;
  padding: 16px 8px;
  margin-bottom: 20px;
}

table td {
  border-left: 0.8px solid $grey-500;
  padding: 16px 8px;
}
table td:first-child {
  border-left: none;
}
