@import '../core/core.scss';
@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.note {
  display: block;
  background-color: $grey-200;
  padding: 8px 16px;
  color: $text-color;
  font-weight: 400;
  border-radius: $border-radius-small;

  strong {
    font-weight: 600;
    color: $black;
    display: block;
    margin-bottom: 4px;
  }
}

p .note,
li .note {
  margin-top: 8px;
}

.example {
  margin: 40px auto;
  text-align: center;
  font-size: 2.2rem;
  font-weight: 700;
  word-break: break-word;
}

.auditColor {
  color: $secondary-color;
}

.explainColor {
  color: $tertiary-color;
}

.trackColor {
  color: $primary-color;
}

.progressButton {
  margin-bottom: 0;
}
