@import '../core/core.scss';
@import '../core/breakpoints.scss';
@import '../core/variables.scss';

.container {
  padding: 10px 10px 5px 20px;
  background: $white;
  border: 1px solid $grey-400;
  border-radius: 4px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-evenly;
  align-items: center;
  gap: 24px;

  ul {
    margin-top: 5px;
  }

  @include medium {
    flex-direction: row;
  }
}

.mascotContainer {
  flex-grow: 1;

  .mascot {
    display: block;
    height: 100%;
    max-height: 100px;
    margin: auto;

    @include medium {
      max-height: 130px;
    }
  }
}
