@import '../core/core.scss';
@import '../core/breakpoints.scss';
@import '../core/variables.scss';

.outerBox {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.separator {
  border-bottom: 1px solid $grey-200;
  margin-bottom: 20px;
}
