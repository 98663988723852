@import './variables';

@mixin large {
  @media only screen and (min-width: $large-screen-min-width) {
    @content;
  }
}

@mixin medium {
  @media only screen and (min-width: $medium-screen-min-width) {
    @content;
  }
}

@mixin medium-wide {
  @media only screen and (min-width: $medium-wide-screen-min-width) {
    @content;
  }
}

@mixin small {
  @media only screen and (min-width: $small-screen-min-width) {
    @content;
  }
}

@mixin site-width {
  @media only screen and (min-width: $site-container-width) {
    @content;
  }
}
