@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.heading {
  text-align: left;

  @media (max-width: 767px) {
    margin-top: 0 !important;
  }
}

.darkBox {
  box-sizing: border-box;
  transition: 0.5s all;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: auto;
  opacity: 1;
  z-index: 610;
  overflow: auto !important;
  box-sizing: border-box;
  width: 100vw;

  * {
    box-sizing: border-box;
  }
}

.hideBox {
  composes: darkBox;
  opacity: 0;
  pointer-events: none;
}

.modal {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  font-size: 1.6rem;
  z-index: 99;
  @include large {
    max-width: 850px;
  }
}
.narrowModal {
  max-width: 580px;
}
.inner {
  background-color: #fff;
  border-radius: $border-radius-small;
  position: relative;
  width: 100%;
}

.head {
  background-color: $primary-color;
  color: $white;
  padding: 10px 30px 10px 10px;
  border-top-right-radius: $border-radius-small;
  border-top-left-radius: $border-radius-small;

  @media (min-width: 768px) {
    padding: 16px 40px 14px 20px;
  }

  h3 {
    margin-bottom: 0;
  }
  * + p {
    margin-top: 16px;
  }
}

.body {
  padding: 10px;
  p {
    margin: 0 0 16px;
  }

  @include medium {
    padding: 20px;
  }

  textarea {
    width: 100%;
    min-height: 100px;
    border: 1px solid $grey-400;
    border-radius: $border-radius-small;
    background-color: $white;
    padding: 8px 12px;
  }
}

.footer {
  padding: 0 10px 10px;

  @include medium {
    padding: 0 20px 20px;
  }
}

.close {
  top: 12px;
  right: 11px;
  height: 0;
  overflow: hidden;
  width: 40px;
  padding: 30px 0 0 0;
  position: absolute;
  cursor: pointer;
  z-index: 30;
}
.whiteModal {
  .head {
    background-color: $grey-100;
    border-bottom: 1px solid $grey-300;
    color: $black;

    p {
      color: $grey-700;
    }
  }

  .close {
    background: transparent url(../assets/icon-close-grey.svg) no-repeat 50%;
  }

  .footer {
    border-top: 1px solid $grey-400;
    padding-top: 16px;
  }
}

.warningModal {
  composes: whiteModal;
  .head {
    background-color: #fff2f3;
    border-bottom: 1px solid $grey-300;
    color: $black;
    background-image: url(../assets/warning-red.svg);
    background-position: 20px 50%;
    background-repeat: no-repeat;
    background-size: 20px;
    padding-left: 50px;
    h3 {
      color: #dc3545;
      font-weight: 500;
    }
  }
  .close {
    opacity: 0.6;
  }
}
