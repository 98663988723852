@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.codeList {
  min-height: 50px;
  margin: 0;
  padding: 0;
  border-top: 1px solid $grey-200;
  margin-bottom: 8px;

  &.scroll {
    height: 140px;
    overflow-y: auto;

    .boxContainer {
      position: absolute;

      .box {
        position: fixed;
        top: unset;
        bottom: unset;
        right: unset;
        left: unset;
      }
    }
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: $grey-400;

    &:hover {
      background-color: $grey-500;
    }
  }
}

.codeListItem {
  list-style: none;
  margin: 0;
  padding: 0 4px;
  text-align: left;
  position: relative;
  white-space: nowrap;
  text-align: left;
  color: $grey-700;
  font-size: $small-font-size;
  font-weight: 500;
  line-height: 40px;
  border-bottom: 1px solid $grey-300;

  &::before {
    display: none !important;
  }

  > label {
    width: 47px;
    position: absolute;
    left: 0;
    top: 8px;
  }

  .hoverOptions {
    display: none;
    justify-content: flex-end;
    align-items: center;
    gap: 0.1em;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50%;

    button {
      border-width: 0;
      height: 100%;
      box-shadow: none;
      margin: 0;
    }
  }

  &:hover {
    &:before {
      content: '';
      display: block;
      width: 100px;
      height: 44px;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        $white 20%,
        $white 100%
      );
      position: absolute;
      right: 0;
      top: 0;
    }

    .hoverOptions {
      display: flex;
    }
  }
}

.animated {
  animation: shake 0.5s;
  animation-iteration-count: 1;
}

.justNow {
  background-color: $light-green;
}
.animatedFirstLi {
  .justNow {
    animation: shake 0.5s;
    overflow: hidden;
  }
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
  overflow: hidden;
}

.showRow {
  min-width: 100%;
  max-width: 100%;
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  position: relative;

  .selectItem {
    flex: none;
    width: auto;
    margin: 8px 0;
  }
}
.tableLink {
  white-space: normal;
  word-break: break-all;
  line-height: 1.6em;
  span span {
    padding: 0;
    background-color: transparent;
  }
}

.inlineDate {
  margin-left: 16px;
  text-align: right;
  color: $grey-400;
}

.linkCode {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.hoverable {
  width: 100%;
  max-width: 100%;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }
}

.box {
  z-index: 10;
  width: 370px;
  max-width: 100%;
  transform: translate(-50%, -44px);
  margin: 0;
  padding: 16px 16px 8px;
  @include medium {
    bottom: 50%;
    transform: translate(-75%, -20px);
  }
}

.divider {
  position: relative;
  margin: 16px auto;

  > div {
    border-top: 1px solid $grey-400;
  }

  > span {
    position: absolute;
    top: -6px;
    left: 8px;
    background-color: $white;
    color: $grey-600;
    padding: 0 6px;
    font-size: $xx-small-font-size;
  }
}

.copyButton {
  background-position: 50% 50%;
}

.confirmDeleteAll {
  &.deleteActionActive {
    .deleteAllButton {
      display: none;
    }

    .content {
      width: 100%;
      opacity: 1;
    }
  }

  .content {
    display: flex;
    justify-content: flex-end;
    width: 0;
    opacity: 0;
    background-color: $white;
    transition: all 0.2s;

    p {
      margin: auto 16px;
      line-height: 1.8em;
      color: $error-red;

      @include medium {
        white-space: nowrap;
        line-height: 1.4em;
      }
    }

    > div {
      width: auto;
    }
  }
}

@keyframes slide {
  0% {
    opacity: 0;
    max-width: 10px;
    background-color: $white;
  }
  1% {
    opacity: 1;
  }
  50% {
    background-color: $light-green;
  }
  100% {
    background-color: $light-green;
    max-width: 1000px;
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
