@import '../core/variables.scss';
@import '../core/breakpoints.scss';
@import './inputs.module.scss';

.footNote {
  color: $grey-600;
  font-size: 1.3rem;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 0;
}
.footNoteError {
  composes: footNote;
  color: $error-red;
}
.footNoteWarning {
  composes: footNote;
  color: $error-red;
}

.labelSlot {
  @include medium {
    min-height: 40px;
  }
}

.tooltip {
  strong {
    font-weight: 400;
    display: block;
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid $grey-200;
    // text-align: left;
    font-style: italic;
  }
}

.multiTags {
  button {
    border-color: #f4d3e0;
    border-width: 0;

    &:hover {
      border-width: 0;
      text-decoration: none;
      background: rgba($primary-color, 0.1);
    }
  }
}

.labelSlot {
  max-height: $button-height;
}

.clearButton {
  position: absolute;
  top: 0;
  right: 30px !important;
}
